import { useCallback } from 'react'
import { UserTenantMgtSvcClient } from '@jarvis/web-stratus-client'
import { useApiCall } from '@jarvis/react-portal-addons'

export default function useUserApiCall({ authProvider, stack, store }) {
  const saveToStore = useCallback(
    (data) => {
      store.setState(
        {
          myAccount: {
            userInfos: {
              ...data
            }
          }
        },
        { deepSafeSetValue: true }
      )
    },
    [store]
  )

  const apiCall = useCallback(async () => {
    try {
      const userTenantClient = new UserTenantMgtSvcClient(stack, authProvider)
      const response = await userTenantClient.getCurrentActiveUserWithTenant()
      const data = response.data
      saveToStore(data)
      return data
    } catch (error) {
      return null
    }
  }, [authProvider, saveToStore, stack])

  return useApiCall({ apiCall })
}

import { useState, useEffect } from 'react'
import useToast from '@veneer/core/dist/scripts/toast_container/use_toast'
import * as T from '../types'

function useAssetImport({ assetReference }: T.LazyMfeLoaderPropsType) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [mfe, setMfe] = useState<any>(undefined)
  const [isLoading, setIsLoading] = useState(true)

  const componentProps = {
    useToast,
    properties: {
      portalLinkBehavior: 'absolute'
    }
  }

  useEffect(() => {
    const importMfe = async () => {
      setMfe(undefined)
      setIsLoading(true)

      window.System.import(assetReference)
        .then((response) => {
          setMfe(response)
        })
        .catch(() => {
          setMfe(undefined)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }

    if (assetReference) importMfe()
  }, [assetReference])

  return { mfe, isLoading, componentProps }
}

export default useAssetImport

import React from 'react'
import useAssetImport from './hooks/useAssetImport'
import * as T from './types'
import Loader from '../Loader'
import { ErrorWidget } from '../ErrorWidget'
import { ErrorBoundary } from '../ErrorBoundary'

const LazyMfeLoader: React.FC<T.LazyMfeLoaderPropsType> = ({
  stack,
  assetReference
}) => {
  const { componentProps, isLoading, mfe } = useAssetImport({
    assetReference
  })
  const session = { isLoggedIn: true }

  if (mfe?.default) {
    return (
      <ErrorBoundary>
        <mfe.default {...{ session, stack, ...componentProps }} />
      </ErrorBoundary>
    )
  }

  if (isLoading) {
    return <Loader />
  }

  return <ErrorWidget />
}

export default LazyMfeLoader

import React from 'react'
import { Container, Message } from './styles'
import IconMinusCircle from '@veneer/core/dist/scripts/icons/icon_minus_circle'
import { useTranslator } from '../../hooks/useTranslator'

export const ErrorWidget = () => {
  const t = useTranslator()

  return (
    <Container data-testid="error-widget">
      <IconMinusCircle color="red6" size={40} />
      <Message>{t('errorBoundary.genericMessage')}</Message>
    </Container>
  )
}

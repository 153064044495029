import { useCallback } from 'react'
import { GrantControllerSvcClient } from '@jarvis/web-stratus-client'
import { useApiCall } from '@jarvis/react-portal-addons'

export default function useGrantsApiCall({ authProvider, stack, store, init }) {
  const saveToStore = useCallback(
    (data) => {
      store.setState(
        {
          myAccount: {
            grants: data
          }
        },
        { deepSafeSetValue: true }
      )
    },
    [store]
  )

  const apiCall = useCallback(
    async (tenantId: string) => {
      try {
        const grantClient = new GrantControllerSvcClient(stack, authProvider)
        const response = await grantClient.getGrants({ tenantId })
        const data = response?.data?.contents || []
        saveToStore(data)
        return data
      } catch (error) {
        return []
      }
    },
    [authProvider, saveToStore, stack]
  )

  return useApiCall({ apiCall, init })
}

import React from 'react'
import { LoadingContainer, LoadingIndicator } from './styles'

const Loader = () => {
  return (
    <LoadingContainer data-testid="loader">
      <LoadingIndicator
        appearance="circular"
        behavior="indeterminate"
        data-testid="loading-indicator"
      />
    </LoadingContainer>
  )
}

export default Loader

import React, { useEffect, memo } from 'react'
import { AcctContainer, AcctNumber, AcctValue } from './styles'
import { useTranslator } from '../../hooks/useTranslator'
import useTenantDetailsApiCall from '../../hooks/useTenantDetailsApiCall'
import { Stack } from '@jarvis/web-stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'

type Props = {
  stack: Stack
  authProvider: JarvisAuthProvider
  tenantId: string
}

const AccountNumber = memo(({ authProvider, stack, tenantId }: Props) => {
  const t = useTranslator()

  const tenantDetails = useTenantDetailsApiCall({
    authProvider,
    stack,
    init: false
  })

  const identifierId = tenantDetails.data?.identifier

  useEffect(() => {
    const fetchTenantDetails =
      tenantId &&
      !identifierId &&
      !tenantDetails.pending &&
      !tenantDetails.success &&
      !tenantDetails.error

    if (fetchTenantDetails) {
      tenantDetails.makeApiCall(tenantId)
    }
  }, [identifierId, tenantDetails, tenantId])

  return (
    <>
      {identifierId && (
        <AcctContainer data-testid="account-number-container">
          <AcctNumber
            className="caption-small"
            htmlFor="account-number-value"
            aria-label="Account Number"
          >
            {t('topBanner.accountNumber')}
          </AcctNumber>
          <AcctValue
            data-testid="account-number-value"
            className="caption-small"
          >
            {identifierId}
          </AcctValue>
        </AcctContainer>
      )}
    </>
  )
})

AccountNumber.displayName = 'AccountNumber'

export default AccountNumber

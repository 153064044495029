import { GATEWAY_ROUTE } from '../constants/routes'

const splitRouteIntoCountryAndLanguage = (path: string) => {
  if (!path) return []

  const [country, language] = path.toLowerCase().split('/').slice(1, 3) || []

  if (country.length !== 2 || language.length !== 2) return []

  return [country, language]
}

/**
 * Generates the pathname of a specific MFE.
 *
 * @param currentPathname - The current pathname of the application.
 * @param pathTargetMfe - The route of the MFE.
 * @returns String
 *
 */
export const generateMfePath = (
  currentPathname: string,
  pathTargetMfe: string,
  queryParams = ''
): string => {
  const [country, language] = splitRouteIntoCountryAndLanguage(currentPathname)
  const basepath = currentPathname.includes(GATEWAY_ROUTE) ? GATEWAY_ROUTE : ''
  const suffix = `${basepath}${pathTargetMfe}${queryParams}`

  if (country && language) {
    return `/${country}/${language}${suffix}`
  }

  return suffix
}

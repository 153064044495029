import React from 'react'
import LazyMfeLoader from '../LazyMfeLoader'
import { Container } from './styles'

type MfeContainerProps = {
  stack: number
  assetReference: string
}

const MfeContainer = ({ stack, assetReference }: MfeContainerProps) => {
  return (
    <Container>
      <LazyMfeLoader assetReference={assetReference} stack={stack} />
    </Container>
  )
}

export default MfeContainer

import React from 'react'
import VeneerAvatar from '@veneer/core/dist/scripts/avatar'
import { AvatarContainer } from './styles'

type AvatarPropsType = {
  firstName: string
  familyName: string
}

const Avatar = ({ firstName, familyName }: AvatarPropsType) => {
  return (
    <AvatarContainer>
      <VeneerAvatar
        label={`${firstName[0] || ''} ${familyName[0] || ''}`.toUpperCase()}
        size={72}
        data-testid="avatar-initials"
      />
    </AvatarContainer>
  )
}

export default Avatar

import React from 'react'
import Tabs from '../Tabs'
import AccountNumber from '../AccountNumber'
import Avatar from '../Avatar'
import Description from '../Description'
import { pathOr } from 'ramda'
import {
  AvatarAndProfileContainer,
  MainContainer,
  ProfileContainer,
  DetailsContainer
} from './styles'
import { StoreType } from '../../types/store'
import { Stack } from '@jarvis/web-stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'

type Props = {
  stack: Stack
  authProvider: JarvisAuthProvider
  store: StoreType
}

const TopBanner = ({ authProvider, stack, store }: Props) => {
  const firstName: string =
    pathOr('', ['myAccount', 'userInfos', 'user', 'givenName'], store?.state) ||
    ''

  const familyName: string =
    pathOr(
      '',
      ['myAccount', 'userInfos', 'user', 'familyName'],
      store?.state
    ) || ''

  const tenantId = pathOr(
    '',
    ['myAccount', 'userInfos', 'userTenantDetail', 'tenantResourceId'],
    store?.state
  )

  return (
    <DetailsContainer>
      <MainContainer>
        <React.Fragment>
          <AvatarAndProfileContainer>
            <Avatar firstName={firstName} familyName={familyName} />
            <ProfileContainer>
              <Description firstName={firstName} familyName={familyName} />
            </ProfileContainer>
          </AvatarAndProfileContainer>
          <AccountNumber
            tenantId={tenantId}
            authProvider={authProvider}
            stack={stack}
          />
        </React.Fragment>
      </MainContainer>
      <Tabs />
    </DetailsContainer>
  )
}

export default TopBanner

export const SCOPES = {
  ACCOUNT_SHAREDUSERS_LIST: 'ws-hp.com/accountmgtsvc/sharedusers.LIST',
  ACCOUNT_USERS_LIST: 'ws-hp.com/accountmgtsvc/users.LIST',
  ACCOUNT_USERS_READ: 'ws-hp.com/accountmgtsvc/users.READ',
  ADDRESS_ADDRESSES_GET: 'ws-hp.com/addresssvc/addresses.GET',
  ADDRESS_ADDRESSES_LIST: 'ws-hp.com/addresssvc/addresses.LIST',
  COMMERCE_ORDERS_READ: 'ws-hp.com/commercemgtsvc/orders.READ',
  COMMERCE_ORDERS_INVOICES_LIST:
    'ws-hp.com/commercemgtsvc/orders/invoices.LIST',
  COMMERCE_ORDERS_INVOICES_READ:
    'ws-hp.com/commercemgtsvc/orders/invoices.READ',
  COMMERCE_PURCHASES_INVOICES_LIST:
    'ws-hp.com/commercemgtsvc/purchases/invoices.LIST',
  COMMERCE_PURCHASES_INVOICES_READ:
    'ws-hp.com/commercemgtsvc/purchases/invoices.READ',
  COMMERCE_SUBSCRIPTIONS_LIST: 'ws-hp.com/commercemgtsvc/subscriptions.LIST',
  COMMERCE_SUBSCRIPTIONS_READ: 'ws-hp.com/commercemgtsvc/subscriptions.READ',
  MASTERBLCONTROLLER_SUBSCRIPTIONS_READ:
    'ws-hp.com/masterblcontroller/subscriptions.READ',
  PAYMENTMETHOD_PAYMENTMETHODS_READ:
    'ws-hp.com/paymentmethodsvc/paymentmethods.READ'
}

import styled from 'styled-components'
import tokens from '@veneer/tokens'
import Tabs from '@veneer/core/dist/scripts/tabs'

export const TabsContainer = styled.div`
  bottom: 0;
  display: flex;
  justify-content: left;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: ${tokens.color.gray4};

  && {
    > div {
      width: 100%;
    }
  }
`

export const TabPanel = styled(Tabs)`
  && {
    background-color: ${tokens.color.gray1};
    border: 0;
    box-shadow: none;
    padding: 0;

    > .vn-tab-list__navigation {
      > .vn-tab-list__navigation-button {
        > div.previous {
          box-shadow: none;
        }

        > div.next {
          box-shadow: none;
        }
      }
    }
    > [aria-label='Tab Navigation'] {
      border-bottom-color: ${tokens.color.gray4};
      > .vn-tab-list__tab {
        min-width: 0px;
        padding: 14px 25px;
        > .vn-tab-list__label {
          min-width: 0px;

          @media (max-width: ${tokens.layout.mdMax}) {
            font-size: ${tokens.typography.size2};
            line-height: ${tokens.typography.lineHeight3};
          }
        }
      }
    }
  }
`

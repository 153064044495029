import { useCallback } from 'react'
import { TenantMgtSvcClient } from '@jarvis/web-stratus-client'
import { useApiCall } from '@jarvis/react-portal-addons'

export default function useTenantDetailsApiCall({ authProvider, stack, init }) {
  const apiCall = useCallback(
    async (tenantId: string) => {
      try {
        const tenantClient = new TenantMgtSvcClient(stack, authProvider)
        const response = await tenantClient.getTenantDetails(tenantId)
        return response.data
      } catch (error) {
        return null
      }
    },
    [authProvider, stack]
  )

  return useApiCall({ apiCall, init })
}

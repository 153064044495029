export const ROUTES = {
  MY_ACCOUNT: '/my-account',
  SUBSCRIPTIONS: '/subscriptions',
  ORDERS: '/orders',
  INVOICES: '/statements',
  SHIPPING_BILLING: '/shipping-billing',
  PROFILE: '/profile',
  USERS: '/manage-users'
}

export const GATEWAY_ROUTE = ROUTES.MY_ACCOUNT

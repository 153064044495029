import { MFE_DATA } from '../constants/mfeData'
import { MfeType } from '../types/myAccount'

/**
 * Returns the list of all Mfes presents in constant MFE_DATA
 */
export const getListOfMfesSupportedByMyAccount = () => {
  const mfes: MfeType[] = []
  for (const mfe in MFE_DATA) mfes.push(MFE_DATA[mfe])
  return mfes
}

import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${tokens.layout.size8};
`

export const Message = styled.p`
  text-align: center;
  margin-top: ${tokens.layout.size3};
`

import React from 'react'
import { Switch, Route, useLocation, Redirect } from 'react-router-dom'
import MfeContainer from '../components/MfeContainer'
import { Stack } from '@jarvis/web-stratus-client'
import { generateMfePath } from '../utils/routing'
import { ROUTES } from '../constants/routes'
import { getListOfMfesSupportedByMyAccount } from '../utils/mfeData'
import { useMfeList } from '../hooks'
import Loader from '../components/Loader'
import ProtectedRoute from './ProtectedRoute'

type Props = {
  stack: Stack
}

export const MainRoutes = ({ stack }: Props) => {
  const location = useLocation()
  const mfeList = useMfeList()

  const getPath = (route = '') => {
    const gatewayRoute = `${ROUTES.MY_ACCOUNT}`
    const suffix = location.pathname.includes(gatewayRoute) ? gatewayRoute : ''
    const basepath = `/:country/:language${suffix}`
    return basepath + route
  }

  const getAssetReference = (route = '') => {
    const supportedMfes = getListOfMfesSupportedByMyAccount()
    const mfe = supportedMfes.find((mfe) => mfe.path === route)
    return mfe.assetReference
  }

  if (!mfeList.length) {
    return <Loader />
  }

  return (
    <Switch>
      <ProtectedRoute path={getPath(ROUTES.SUBSCRIPTIONS)}>
        <MfeContainer
          stack={stack}
          assetReference={getAssetReference(ROUTES.SUBSCRIPTIONS)}
        />
      </ProtectedRoute>

      <ProtectedRoute path={getPath(ROUTES.ORDERS)}>
        <MfeContainer
          stack={stack}
          assetReference={getAssetReference(ROUTES.ORDERS)}
        />
      </ProtectedRoute>

      <ProtectedRoute path={getPath(ROUTES.INVOICES)}>
        <MfeContainer
          stack={stack}
          assetReference={getAssetReference(ROUTES.INVOICES)}
        />
      </ProtectedRoute>

      <ProtectedRoute path={getPath(ROUTES.SHIPPING_BILLING)}>
        <MfeContainer
          stack={stack}
          assetReference={getAssetReference(ROUTES.SHIPPING_BILLING)}
        />
      </ProtectedRoute>

      <ProtectedRoute path={getPath(ROUTES.PROFILE)}>
        <MfeContainer
          stack={stack}
          assetReference={getAssetReference(ROUTES.PROFILE)}
        />
      </ProtectedRoute>

      <ProtectedRoute path={getPath(ROUTES.USERS)}>
        <MfeContainer
          stack={stack}
          assetReference={getAssetReference(ROUTES.USERS)}
        />
      </ProtectedRoute>

      <Route path={getPath()}>
        <Redirect
          to={generateMfePath(
            location.pathname,
            mfeList[0].path,
            location.search
          )}
        />
      </Route>
    </Switch>
  )
}

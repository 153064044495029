import React, { useState, useEffect, useMemo } from 'react'
import { useTranslator } from '../../hooks/useTranslator'
import { TabsContainer, TabPanel } from './styles'
import { useLocation, useHistory } from 'react-router-dom'
import { generateMfePath } from '../../utils/routing'
import { useMfeList } from '../../hooks'

const Tabs = () => {
  const [selectedTabId, setSelectedTabId] = useState(undefined)
  const mfeList = useMfeList()
  const { pathname, search } = useLocation()
  const history = useHistory()
  const t = useTranslator()

  useEffect(() => {
    const updateSelectedTabId = () => {
      const newTab = mfeList.find((mfe) =>
        pathname.startsWith(generateMfePath(pathname, mfe.path))
      )

      if (newTab) setSelectedTabId(newTab.id)
    }

    if (pathname && mfeList.length) {
      updateSelectedTabId()
    }
  }, [pathname, mfeList])

  const goToPage = (pageId: number) => {
    const targetMfe = mfeList.find((mfe) => mfe.id === pageId)
    history.push(generateMfePath(pathname, targetMfe.path, search))
  }

  const MfeTabs = useMemo(() => {
    return mfeList
      .map((mfe) => ({ id: mfe.id, label: t(mfe.texts.label) }))
      .filter(Boolean)
  }, [mfeList, t])

  if (MfeTabs.length > 0 && selectedTabId !== undefined) {
    return (
      <TabsContainer data-testid="tabs">
        <TabPanel
          controlId="default"
          mode="contained"
          onChangeTab={(id) => goToPage(id)}
          selectedTabId={selectedTabId}
          tabs={MfeTabs}
          type="online"
        />
      </TabsContainer>
    )
  }

  return null
}

export default Tabs

import { useEffect, useState } from 'react'

export enum FeatureFlagsEnum {
  checkScopesForProfileAndUserMenus = 'legacy-check-scopes-for-profile-and-user-menus',
  checkCountriesForCommerceMenusByJson = 'legacy-check-countries-for-commerce-menus-by-json'
}

const featureFlagsDefaultValues = {
  [FeatureFlagsEnum.checkScopesForProfileAndUserMenus]: false,
  [FeatureFlagsEnum.checkCountriesForCommerceMenusByJson]: []
}

const useFeatureFlags = (key: string) => {
  const getClient = window?.Shell?.v1?.featureFlags?.getClient
  const defaultValue = featureFlagsDefaultValues[key] || false
  const [featureFlagValue, setFeatureFlagValue] = useState(defaultValue)

  useEffect(() => {
    const getFeatureFlagValue = async () => {
      const client = await getClient('myaccount')
      const featureFlagValue = await client?.getFeatureFlag({
        key,
        defaultValue
      })

      setFeatureFlagValue(featureFlagValue)
    }

    getFeatureFlagValue()
  }, [key, getClient, defaultValue, setFeatureFlagValue])

  return featureFlagValue
}

export default useFeatureFlags

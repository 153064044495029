import styled from 'styled-components'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'

export const LoadingContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const LoadingIndicator = styled(ProgressIndicator)``

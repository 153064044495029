import React, { createContext, useState, useEffect, useCallback } from 'react'
import { MFE_DATA } from '../constants/mfeData'
import { MfeType } from '../types/myAccount'
import { getListOfMfesSupportedByMyAccount } from '../utils/mfeData'
import { CheckScopesParamsType } from '../types/shell'
import { AccessControlType } from '../types/mfeProps'
import { pathOr } from 'ramda'
import useFeatureFlags, { FeatureFlagsEnum } from '../hooks/useFeatureFlags'

type MfeManager = {
  mfeList: MfeType[]
}

type Props = {
  children: React.ReactNode
  countrySet: Array<string>
  accessControl: AccessControlType
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store: any
}

export const MfeManagerContext = createContext<MfeManager>({
  mfeList: []
})

export const MfeManagerProvider = ({
  children,
  countrySet,
  accessControl,
  store
}: Props) => {
  const [mfeList, setMfeList] = useState<MfeType[]>([])
  const checkScopesForProfileAndUserMenus = useFeatureFlags(
    FeatureFlagsEnum.checkScopesForProfileAndUserMenus
  )
  const checkCountriesForCommerceMenusByJson = useFeatureFlags(
    FeatureFlagsEnum.checkCountriesForCommerceMenusByJson
  )

  const accessTokenHasAllMfeScopes = useCallback(
    async (mfeScopeGroup: string[]): Promise<boolean> => {
      const scopeList: CheckScopesParamsType = mfeScopeGroup.map((scope) => ({
        scope
      }))

      return await accessControl.checkScopes(scopeList)
    },
    [accessControl]
  )

  const mfeHasGrantsToAppear = (mfe: MfeType, userGrants): boolean => {
    return userGrants.length
      ? mfe.grants.some((grant) => userGrants.includes(grant))
      : false
  }

  const filterMfeListByGrants = useCallback(
    async (mfeList): Promise<MfeType[]> => {
      const grants = pathOr([], ['myAccount', 'grants'], store?.state).map(
        (item) => item.grant
      )

      return mfeList.filter((mfe) =>
        mfe.grants ? mfeHasGrantsToAppear(mfe, grants) : true
      )
    },
    [store]
  )

  const filterMfeListByCommerceScopes = useCallback(
    async (mfeList): Promise<MfeType[]> => {
      const mfesIdListToRemove: number[] = []

      if (!(await accessTokenHasAllMfeScopes(MFE_DATA.INVOICES.scopeGroup))) {
        mfesIdListToRemove.push(MFE_DATA.INVOICES.id)
      }

      if (!(await accessTokenHasAllMfeScopes(MFE_DATA.ORDERS.scopeGroup))) {
        mfesIdListToRemove.push(MFE_DATA.ORDERS.id)
      }

      if (
        !(await accessTokenHasAllMfeScopes(
          MFE_DATA.SHIPPING_BILLING.scopeGroup
        ))
      ) {
        mfesIdListToRemove.push(MFE_DATA.SHIPPING_BILLING.id)
      }

      if (
        !(await accessTokenHasAllMfeScopes(MFE_DATA.SUBSCRIPTIONS.scopeGroup))
      ) {
        mfesIdListToRemove.push(MFE_DATA.SUBSCRIPTIONS.id)
      }

      return mfeList.filter((mfe) => !mfesIdListToRemove.includes(mfe.id))
    },
    [accessTokenHasAllMfeScopes]
  )

  const filterMfeListByProfileAndUserScopes = useCallback(
    async (mfeList): Promise<MfeType[]> => {
      const mfesIdListToRemove: number[] = []

      if (!(await accessTokenHasAllMfeScopes(MFE_DATA.PROFILE.scopeGroup))) {
        mfesIdListToRemove.push(MFE_DATA.PROFILE.id)
      }

      if (!(await accessTokenHasAllMfeScopes(MFE_DATA.USERS.scopeGroup))) {
        mfesIdListToRemove.push(MFE_DATA.USERS.id)
      }

      return mfeList.filter((mfe) => !mfesIdListToRemove.includes(mfe.id))
    },
    [accessTokenHasAllMfeScopes]
  )

  const filterMfeListByCountriesForCommerceMenus = useCallback(
    async (supportedCountriesToSeeCommerceMenus, mfeList) => {
      const mfesIdListToRemove = [
        MFE_DATA.INVOICES.id,
        MFE_DATA.ORDERS.id,
        MFE_DATA.SHIPPING_BILLING.id,
        MFE_DATA.SUBSCRIPTIONS.id
      ]

      if (
        !countrySet ||
        countrySet.length === 0 ||
        !supportedCountriesToSeeCommerceMenus.some((country) =>
          countrySet.includes(country)
        )
      ) {
        return mfeList.filter((mfe) => !mfesIdListToRemove.includes(mfe.id))
      }

      return mfeList
    },
    [countrySet]
  )

  useEffect(() => {
    const updateMfeList = async () => {
      let newMfeList: MfeType[] = getListOfMfesSupportedByMyAccount()

      newMfeList = await filterMfeListByGrants(newMfeList)

      newMfeList = await filterMfeListByCommerceScopes(newMfeList)

      if (checkScopesForProfileAndUserMenus) {
        newMfeList = await filterMfeListByProfileAndUserScopes(newMfeList)
      }

      const supportedCountriesToSeeCommerceMenus: string[] = checkCountriesForCommerceMenusByJson

      if (supportedCountriesToSeeCommerceMenus.length) {
        newMfeList = await filterMfeListByCountriesForCommerceMenus(
          supportedCountriesToSeeCommerceMenus,
          newMfeList
        )
      }

      setMfeList(newMfeList)
    }
    updateMfeList()
  }, [
    checkCountriesForCommerceMenusByJson,
    checkScopesForProfileAndUserMenus,
    filterMfeListByCommerceScopes,
    filterMfeListByCountriesForCommerceMenus,
    filterMfeListByGrants,
    filterMfeListByProfileAndUserScopes
  ])

  return (
    <MfeManagerContext.Provider value={{ mfeList }}>
      {children}
    </MfeManagerContext.Provider>
  )
}

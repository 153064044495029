import React, { createContext } from 'react'
import { TranslatorFunctionType } from '../types/localization'
interface PropsType {
  children: React.ReactNode
  t: TranslatorFunctionType
}

interface MyAccountType {
  t: TranslatorFunctionType
}

export const MyAccountContext = createContext<MyAccountType>(null)

export const MyAccountProvider = ({ children, t }: PropsType) => {
  return (
    <MyAccountContext.Provider value={{ t }}>
      {children}
    </MyAccountContext.Provider>
  )
}

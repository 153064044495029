import React, { memo } from 'react'
import { Route } from 'react-router'
import { Redirect } from 'react-router-dom'
import { useMfeList } from '../hooks'
import { GATEWAY_ROUTE } from '../constants/routes'

type Props = {
  path: string
  children: JSX.Element
}

function isPathUnchanged(oldProps, newProps) {
  return oldProps.path === newProps.path
}

const MyAccountRoute = ({ path, children }: Props) => {
  const mfeList = useMfeList()

  const pathIsInTheList =
    mfeList.filter((mfe) => {
      const suffix = `/:country/:language${GATEWAY_ROUTE}`
      return `${suffix}${mfe.path}` === path
    }).length !== 0

  if (pathIsInTheList) {
    return (
      <Route exact path={path}>
        {children}
      </Route>
    )
  }

  return <Redirect from={path} to="my-account" />
}

const ProtectRoute = memo(MyAccountRoute, isPathUnchanged)

export default ProtectRoute

import React from 'react'
import { Container, Text } from './styles'

type DescriptionPropsType = {
  firstName: string
  familyName: string
}

const Description = ({ firstName, familyName }: DescriptionPropsType) => {
  return (
    <Container data-testid="description">
      <Text className="body-large">{`${firstName} ${familyName}`}</Text>
    </Container>
  )
}

export default Description

import { ROUTES } from './routes'
import { GRANTS } from './grants'
import { SCOPES } from './scopes'

export const MFE_DATA = {
  SUBSCRIPTIONS: {
    id: 0,
    assetReference: '@monetization/subscription-management-react',
    texts: {
      label: 'topBanner.tabs.subscriptions.label',
      description: 'topBanner.tabs.subscriptions.description'
    },
    grants: [GRANTS.PAAS, GRANTS.PCAAS, GRANTS.PRINT_PLANS],
    path: ROUTES.SUBSCRIPTIONS,
    ldClientIdSrc: 'commerce',
    scopeGroup: [
      SCOPES.COMMERCE_SUBSCRIPTIONS_READ,
      SCOPES.COMMERCE_SUBSCRIPTIONS_LIST,
      SCOPES.MASTERBLCONTROLLER_SUBSCRIPTIONS_READ
    ]
  },
  ORDERS: {
    id: 1,
    assetReference: '@monetization/order-management-react',
    texts: {
      label: 'topBanner.tabs.orderHistory.label',
      description: 'topBanner.tabs.orderHistory.description'
    },
    grants: [GRANTS.PAAS, GRANTS.PCAAS, GRANTS.PRINT_PLANS],
    path: ROUTES.ORDERS,
    ldClientIdSrc: 'commerce',
    scopeGroup: [
      SCOPES.COMMERCE_ORDERS_READ,
      SCOPES.COMMERCE_SUBSCRIPTIONS_READ,
      SCOPES.COMMERCE_SUBSCRIPTIONS_LIST,
      SCOPES.MASTERBLCONTROLLER_SUBSCRIPTIONS_READ
    ]
  },
  INVOICES: {
    id: 2,
    assetReference: '@monetization/invoices-management-react',
    texts: {
      label: 'topBanner.tabs.invoices.label',
      description: 'topBanner.tabs.invoices.description'
    },
    grants: [GRANTS.PAAS, GRANTS.PCAAS, GRANTS.PRINT_PLANS],
    path: ROUTES.INVOICES,
    ldClientIdSrc: 'commerce',
    scopeGroup: [
      SCOPES.COMMERCE_ORDERS_READ,
      SCOPES.COMMERCE_ORDERS_INVOICES_LIST,
      SCOPES.COMMERCE_ORDERS_INVOICES_READ,
      SCOPES.COMMERCE_PURCHASES_INVOICES_LIST,
      SCOPES.COMMERCE_PURCHASES_INVOICES_READ
    ]
  },
  SHIPPING_BILLING: {
    id: 3,
    assetReference: '@monetization/shipping-billing-management-react',
    texts: {
      label: 'topBanner.tabs.shippingAndBilling.label',
      description: 'topBanner.tabs.shippingAndBilling.description'
    },
    grants: [GRANTS.PAAS, GRANTS.PCAAS, GRANTS.PRINT_PLANS],
    path: ROUTES.SHIPPING_BILLING,
    ldClientIdSrc: 'commerce',
    scopeGroup: [
      SCOPES.ADDRESS_ADDRESSES_GET,
      SCOPES.ADDRESS_ADDRESSES_LIST,
      SCOPES.PAYMENTMETHOD_PAYMENTMETHODS_READ
    ]
  },
  PROFILE: {
    id: 4,
    assetReference: '@jarvis/react-hpx-profile',
    texts: {
      label: 'topBanner.tabs.profile.label',
      description: 'topBanner.tabs.profile.description'
    },
    path: ROUTES.PROFILE,
    scopeGroup: [SCOPES.ACCOUNT_USERS_LIST, SCOPES.ACCOUNT_USERS_READ]
  },
  USERS: {
    id: 5,
    assetReference: '@jarvis/react-hpx-users',
    texts: {
      label: 'topBanner.tabs.users.label',
      description: 'topBanner.tabs.users.description'
    },
    path: ROUTES.USERS,
    scopeGroup: [
      SCOPES.ACCOUNT_USERS_LIST,
      SCOPES.ACCOUNT_USERS_READ,
      SCOPES.ACCOUNT_SHAREDUSERS_LIST
    ]
  }
}

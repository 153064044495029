import { useCallback } from 'react'
import { AccountMgtSvcClient } from '@jarvis/web-stratus-client'
import { useApiCall } from '@jarvis/react-portal-addons'

export default function useAccountApiCall({ authProvider, stack }) {
  const apiCall = useCallback(async () => {
    try {
      const accountClient = new AccountMgtSvcClient(stack, authProvider)
      const response = await accountClient.getAccount()
      const data = response.data
      return data
    } catch (error) {
      return null
    }
  }, [authProvider, stack])

  return useApiCall({ apiCall })
}

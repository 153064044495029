import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const DetailsContainer = styled.div``

export const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 104px;
  position: relative;
  padding: ${tokens.layout.size5} 40px;
  background-color: ${tokens.color.gray2};

  @media (max-width: ${tokens.layout.smMin}) {
    display: flex;
    flex-direction: column;
    padding: 28px ${tokens.layout.size8};
    justify-content: center;
  }
`

export const AvatarAndProfileContainer = styled.div`
  display: flex;

  @media (max-width: ${tokens.layout.smMin}) {
    flex-direction: column;
  }
`

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: ${tokens.layout.size4};

  @media (max-width: ${tokens.layout.smMin}) {
    align-items: center;
    margin-left: 0px;
  }
`

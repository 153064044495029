import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
`
export const Text = styled.p`
  color: ${tokens.color.gray7};

  @media (max-width: ${tokens.layout.smMin}) {
    text-align: center;
    margin-top: ${tokens.layout.size4};
  }
`

import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const AvatarContainer = styled.div`
  display: flex;
  justify-content: right;
  align-items: center;
  > div {
    background-color: rgba(2, 122, 174, 0.1);
    width: 64px;
    height: 64px;
    border-radius: 140px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
  > div > span {
    color: ${tokens.color.hpBlue8};
    font-size: ${tokens.typography.size4};
    font-weight: 400;
    line-height: ${tokens.typography.lineHeight4};
    letter-spacing: 0.01em;
    text-align: center;
  }

  > div[class='css-1o2vxqg'] > svg[class='css-1eigj3y'] {
    fill: ${tokens.color.hpBlue8};
  }

  @media (max-width: ${tokens.layout.smMax}) {
    justify-content: center;
  }
`

import React from 'react'
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary'
import { ErrorWidget } from '../ErrorWidget'

export const ErrorBoundary = ({ children }) => {
  return (
    <ReactErrorBoundary fallbackRender={() => <ErrorWidget />}>
      {children}
    </ReactErrorBoundary>
  )
}

import { useCallback } from 'react'
import { useApiCall } from '@jarvis/react-portal-addons'
import useFeatureFlags, { FeatureFlagsEnum } from './useFeatureFlags'
import { AccessControlType } from '../types/mfeProps'

type ScopesApiCallPropsType = {
  accessControl: AccessControlType
}

export default function useRefreshScopesApiCall({
  accessControl
}: ScopesApiCallPropsType) {
  const checkScopesForProfileAndUserMenus = useFeatureFlags(
    FeatureFlagsEnum.checkScopesForProfileAndUserMenus
  )

  const apiCall = useCallback(async () => {
    if (checkScopesForProfileAndUserMenus) {
      return await accessControl.refresh()
    }

    return Promise.resolve()
  }, [accessControl, checkScopesForProfileAndUserMenus])

  return useApiCall({ apiCall })
}

import styled from 'styled-components'
import tokens from '@veneer/tokens'

export const AcctContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-left: ${tokens.layout.size5};

  @media (max-width: ${tokens.layout.smMin}) {
    flex-direction: row;
    margin-top: ${tokens.layout.size1};
    align-items: center;
    justify-content: center;
    padding-left: 0;
  }
`

export const AcctNumber = styled.label`
  color: ${tokens.color.gray5};
  text-align: end;

  @media (max-width: ${tokens.layout.smMin}) {
    margin-right: ${tokens.layout.smMin};
  }
`

export const AcctValue = styled.span`
  color: ${tokens.color.gray7};
  margin-top: 2px;

  @media (max-width: ${tokens.layout.smMin}) {
    margin-top: 0;
  }
`
